import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { intervalToDuration, formatDuration } from 'date-fns';
import useInterval from './useInterval';
import dungeons, { Dungeon } from './dungeons';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import writeRoulette from './databaseWriter';

export interface FormData {
    startTime: string,
    endTime: string,
    instanceCategory: string,
    instance: string,
    job: string,
    inProgress: boolean,
    notes: string
}

const schema = yup.object({
    startTime: yup.string().required(),
    endTime: yup.string().required(),
    instanceCategory: yup.string().required().oneOf(Object.keys(dungeons)),
    instance: yup.string().required().oneOf(Object.values(dungeons).flat()),
    job: yup.string().required().length(3).uppercase(),
    inProgress: yup.bool().required(),
    notes: yup.string()
});

const blankForm = {startTime: "", endTime: "", instanceCategory: "", instance: "", job: "", inProgress: false, notes: ""};

function showTimeDiff(startTime: string, endTime: string): string {
    const duration = intervalToDuration({
        start: new Date(startTime), 
        end: endTime.length === 0 ? new Date() : new Date(endTime),
    });

    return formatDuration(duration, {delimiter: ', '});
}

function MentorForm() {
    const [ timeFromNow, setTimeFromNow ] = useState('');
    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm<FormData>({ resolver: yupResolver(schema) });
    const onSubmit = handleSubmit(async (data) => { 
        try {
            await writeRoulette(data);
            toast.success(`Successfully submitted data for ${data.instance}`);
            reset(blankForm);
        } catch(err) {
            console.log(err);
            if (err instanceof Error) {
                toast.error(err.message);
            } else {
                toast.error('An Unknown Error Occurred');
            }
        }
    });
    const startTime = watch('startTime','');
    const endTime = watch('endTime', '');

    useInterval(() => {
        if(startTime.length > 0 && endTime.length === 0) {
            setTimeFromNow(showTimeDiff(startTime, ''));
        } else {
            setTimeFromNow('');
        }
    }, 1000);

    const instanceCategory = watch('instanceCategory', '');
    let selectOptions: string[] = [];

    if (instanceCategory === '') {
        selectOptions = [];
    } else {
        selectOptions = (dungeons as Dungeon)[instanceCategory] ?? [];
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if(name === 'instanceCategory' && type === 'change') {
                setValue('instance', '');
            }
        });
        return () => subscription.unsubscribe();
      }, [watch, setValue]);

    return (
        <div>
            <h1>Roulette Details</h1>
            <form onSubmit={onSubmit}>
                <button onClick={() => { setValue('startTime', (new Date()).toISOString()) }} className="button is-solid m-r-10" type="button" disabled={startTime.length > 0}>Start Time</button>
                <button onClick={() => { setValue('endTime', (new Date()).toISOString())}} className="button is-solid" type="button" disabled={startTime.length === 0 || endTime.length > 0}>End Time</button>
                <input type="hidden" {...register("startTime", {required: true })} />
                <input type="hidden" {...register("endTime", {required: true })} />
                <div className="m-t-10 m-b-10 time-display">
                    {startTime.length > 0 && endTime.length > 0 && <span>{showTimeDiff(startTime, endTime)}</span>}
                    {startTime.length === 0 && endTime.length === 0 && <span>Hit Start</span>}
                    {startTime.length > 0 && endTime.length === 0 && <span>{timeFromNow}</span>}
                    {errors.startTime && <div className="help-text is-red-500">{errors.startTime.message}</div>}
                    {errors.endTime && <div className="help-text is-red-500">{errors.endTime.message}</div>}
                </div>

                <div className="input-group is-stacked responsive m-t-15">
                    <label>Instance Category</label>
                    <div className="input has-arrow">
                        <select {...register("instanceCategory", {required: true })}>
                            <option value="">Select a Category</option>
                            {Object.keys(dungeons).map(category => <option key={category}>{category}</option>)}
                        </select>
                    </div>
                    {errors.instanceCategory && <div className="help-text is-red-500">{errors.instanceCategory.message}</div>}
                </div>

                <div className="input-group is-stacked responsive m-t-15">
                    <label>Instance</label>
                    <div className="input has-arrow">
                        <select {...register("instance", {required: true })}>
                            <option value="">{instanceCategory === '' ? 'Select a Instance Category' : 'Select a Dungeon'}</option>
                            {selectOptions.map(dungeon => <option key={dungeon}>{dungeon}</option>)}
                        </select>
                    </div>
                    {errors.instance && <div className="help-text is-red-500">{errors.instance.message}</div>}
                </div>

                <div className="input-group is-stacked responsive m-t-15">
                    <label>Job</label>
                    <div className="input has-arrow">
                        <select {...register("job", {required: true })}>
                            <option value="">Select A Job</option>
                            <option value="PLD">Paladin</option>
                            <option value="WAR">Warrior</option>
                            <option value="DRK">Dark Knight</option>                            
                            <option value="GNB">Gunbreaker</option>

                            <option value="SCH">Scholar</option> 
                            <option value="SGE">Sage</option>
                            <option value="AST">Astrologian</option>
                            <option value="WHM">White Mage</option>

                            <option value="RPR">Reaper</option>
                            <option value="DRG">Dragoon</option>
                            <option value="MNK">Monk</option>
                            <option value="SAM">Samurai</option>
                            <option value="NIN">Ninja</option>
                            <option value="VPR">Viper</option>

                            <option value="BRD">Bard</option>
                            <option value="DNC">Dancer</option>
                            <option value="MCH">Machinist</option>

                            <option value="BLM">Black Mage</option>
                            <option value="RDM">Red Mage</option>
                            <option value="SMN">Summoner</option>
                            <option value="PCT">Pictomancer</option>
                        </select>
                    </div>
                    {errors.job && <div className="help-text is-red-500">{errors.job.message}</div>}
                </div>

                <div className="input-group is-stacked responsive m-t-15">
                    <div className="checkbox">
                        <input type="checkbox" id="in-progress-checkbox" {...register('inProgress')} />
                        <label htmlFor="in-progress-checkbox">Duty In Progress?</label>
                    </div>
                </div>

                <div className="input-group is-stacked responsive m-t-15">
                    <label>Notes</label>
                    <div className="input">
                        <textarea cols={30} rows={5} {...register('notes')}></textarea>
                    </div>
                </div>
                <div className="m-t-20">
                    <input type="submit" className="button is-solid m-r-10"/>
                    <button className="button is-outline" type="button" onClick={() => reset(blankForm)}>Reset</button>
                </div>
            </form>
        </div>
    )
}

export default MentorForm;